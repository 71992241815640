html,
body {
  height: 100%;
  margin: 0;
  /*font-family: Arial, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";/*Inter; /*"Source Sans Pro", ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";*/ /* "IBM Plex Sans", sans-serif;/*,  */
  font-family: "Roboto", serif;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
  -webkit-font-smoothing: auto;
  scrollbar-color: #00000055 transparent;
  scrollbar-width: thin;
  font-size: 13px;
  /*letter-spacing: .1px,*/ 
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.CodeMirror {
  width: 100% !important;
  height: 100% !important;
}

/*
body.colResizeCursors * {
    cursor: col-resize !important;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes wiggle {
  0% {
    transform: rotateZ(0);
  }
  24% {
    transform: rotateZ(-15deg);
  }
  39% {
    transform: rotateZ(10deg);
  }
  54% {
    transform: rotateZ(-10deg);
  }
  69% {
    transform: rotateZ(6deg);
  }
  84% {
    transform: rotateZ(-4deg);
  }
  100% {
    transform: rotateZ(0);
  }
}

@keyframes tilt-n-move-shaking {
  0% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(5px, 5px) rotate(5deg); }
  50% { transform: translate(0, 0) rotate(0eg); }
  75% { transform: translate(-5px, 5px) rotate(-5deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}*/